import {computePosition, flip, shift} from "@floating-ui/dom";

export function updatePosition(anchor: HTMLElement, element: HTMLElement) {
  // Compute the position of the element and update the style
  computePosition(anchor, element, {
    placement: "bottom-start",
    middleware: [flip(), shift({padding: 10})]
  }).then(({x, y}) => {
    Object.assign(element.style, {
      left: `${x}px`,
      top: `${y}px`,
    });

  });
}