import {signIn} from "auth-astro/client"
import {OpenIDConnections} from "../../data/connections_list_live.json"

export async function signInCredentials(
  email: string,
  password: string,
) {
  const res = await signIn("credentials", {
    Email: email,
    Password: password,
    redirect: false,
    callbackUrl: "/user/"
  } as any)
    .then(async (res) => {
      console.log("Signin res: ", res)
      const data = await res.json() as {
        url: string
      }
      if (data.url.includes("error")) {
        throw new Error("Invalid credentials")
      } else {
        return true
      }

    }).catch((err) => {
      throw new Error("Error signing in")
    })
}

export async function getUniSSOAuthUrl(email: string) {
  const suffix = email.split("@")[1]

  const connection = OpenIDConnections.find((c) => c.suffix === suffix)
  if (!connection) {
    throw new Error("No connection found")
  }

  return generateUniSSOAuthURL(connection)

}

function generateUniSSOAuthURL(connection: typeof OpenIDConnections[number]) {
  const oidcMechanism = connection.mechanism

  return oidcMechanism.authorizeURL + // required
    "?client_id=" +
    connection.clientID + //required
    "&redirect_uri=" +
    window.location.href +
    "/auth/UniSSO" + //required
    "&response_type=" +
    (oidcMechanism.responseType || "id_token") +
    "&scope=" + // required
    (oidcMechanism.scopes || "openid") +
    "&response_mode=" +
    (oidcMechanism.responseMode || "form_post") + // required
    "&nonce=" +
    Math.random().toString(36).slice(2) // required random nonce string
}